import React from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import DarkenedBackgroundImage from "../components/darkened-background-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PatternContainer from "../components/pattern-container";
import Button from "../components/button";

import AllMP4 from "../videos/All.mp4";

function AboutPage({ data }) {
  const headline = data.prismicAbout.data.process_headline;

  return (
    <Layout>
      <SEO
        title={headline}
        description={data.prismicAbout.data.seo_description}
        keywords={data.prismicAbout.data.seo_keywords
          ?.split(",")
          .map(keyword => keyword.trim())}
      />
      <section className="relative">
        <div
          className={`w-full h-full flex items-center justify-center`}
          style={{
            maxHeight: "337px",
            background: "rgba(0,0,0,0.2)"
          }}
        >
          <video
            autoPlay
            playsInline
            loop
            muted
            className="w-full relative"
            style={{
              zIndex: -1
            }}
          >
            <source src={AllMP4} type="video/mp4" />
          </video>
          <h1 className="text-white absolute w-full h-full flex items-center justify-center top-0 left-0">
            {headline}
          </h1>
        </div>
      </section>

      <PatternContainer className="px-4 py-20">
        <section className="px-4 mb-20">
          <p className="text-center px-4 md:max-w-xl mx-auto text-lg font-bold">
            {data.prismicAbout.data.process_abstract}
          </p>
        </section>
        <section className="max-w-5xl mx-auto">
          <Step
            title="Sponge"
            imageData={data.prismicAbout.data.sponge_image.gatsbyImageData}
            className="mb-8"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.sponge_description.html
              }}
            />
          </Step>
          <Step
            title="Mix and Roll"
            imageData={
              data.prismicAbout.data.mix_and_roll_image.gatsbyImageData
            }
            className="mb-8"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.mix_and_roll_description.html
              }}
            />
          </Step>
          <Step
            title="Retard/Fridge"
            imageData={data.prismicAbout.data.retard_image.gatsbyImageData}
            className="mb-8"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.retard_description.html
              }}
            />
          </Step>
          <Step
            title="Boil"
            imageData={data.prismicAbout.data.boil_image.gatsbyImageData}
            className="mb-8"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.boil_description.html
              }}
            />
          </Step>
          <Step
            title="Seed"
            imageData={data.prismicAbout.data.seed_image.gatsbyImageData}
            className="mb-8"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.seed_description.html
              }}
            />
          </Step>
          <Step
            title="Bake"
            imageData={data.prismicAbout.data.bake_image.gatsbyImageData}
            className="mb-8"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.bake_description.html
              }}
            />
          </Step>
          <Step
            title="Top"
            imageData={data.prismicAbout.data.top_image.gatsbyImageData}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.top_description.html
              }}
            />
          </Step>
        </section>
      </PatternContainer>
      <AboutTom
        headline={data.prismicAbout.data.about_tom_headline}
        blurb={data.prismicAbout.data.about_tom_text}
        image={data.prismicAbout.data.tom_profile.gatsbyImageData}
      />
      <BagelMaster
        image={data.prismicAbout.data.bagel_master_background.gatsbyImageData}
      />
    </Layout>
  );
}

function Step({ title, imageData, children, className }) {
  return (
    <div className={classNames("flex flex-wrap", className)}>
      <div className="w-full md:w-1/2">
        <GatsbyImage image={imageData} className="w-full" />
      </div>
      <div className="bg-white text-rosens-green-kelp p-8 md:px-4 md:px-16 w-full md:w-1/2 md:flex md:flex-col md:justify-center">
        <h3 className="mb-4 text-left">{title}</h3>
        {children}
      </div>
    </div>
  );
}

function AboutTom({ headline, blurb, image }) {
  return (
    <section className="bg-rosens-caramel md:bg-rosens-papaya-whip w-full">
      <div className="md:flex md:flex-wrap max-w-5xl mx-auto px-4 py-12 md:py-24 text-rosens-green-kelp">
        <h1 className="mb-8 md:hidden">{headline}</h1>
        <div className="mb-8 md:w-1/2 md:px-10">
          <GatsbyImage image={image} className="rounded-full" />
        </div>
        <div className="md:w-1/2 md:px-10">
          <h1 className="hidden md:block md:text-left">{headline}</h1>
          <p className="text-base">{blurb}</p>
        </div>
      </div>
    </section>
  );
}

function BagelMaster({ image }) {
  return (
    <DarkenedBackgroundImage
      Tag="section"
      image={image}
      className="text-white py-12 px-8 flex flex-col items-center justify-center"
    >
      <h2 className="mb-8">Inside The Life of a Bagel Master</h2>
      <Button
        size="big"
        variant="burningOrange"
        className="md:bg-transparent md:bg-transparent md:border-white md:border-2 md:px-16"
      >
        Explore Now
      </Button>
    </DarkenedBackgroundImage>
  );
}
export const query = graphql`
  query AboutPageQuery {
    prismicAbout {
      data {
        seo_description
        seo_keywords
        sponge_image {
          gatsbyImageData(
            width: 1012
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
        sponge_description {
          html
        }
        mix_and_roll_image {
          gatsbyImageData(
            width: 1012
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
        mix_and_roll_description {
          html
        }
        retard_image {
          gatsbyImageData(
            width: 1012
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
        retard_description {
          html
        }
        boil_image {
          gatsbyImageData(
            width: 1012
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
        boil_description {
          html
        }
        seed_image {
          gatsbyImageData(
            width: 1012
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
        seed_description {
          html
        }
        bake_image {
          gatsbyImageData(
            width: 1012
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
        bake_description {
          html
        }
        top_image {
          gatsbyImageData(
            width: 1012
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
        top_description {
          html
        }
        tom_profile {
          gatsbyImageData(
            width: 1012
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
        bagel_master_background {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        about_tom_headline
        about_tom_text
        process_abstract
        process_headline
      }
    }
  }
`;

export default AboutPage;
